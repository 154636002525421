<template>
    <v-card elevation="2"
  outlined
  tile
  class="mx-auto my-4"
  max-width="600"
  >
    <v-card-title class="font-weight-bold">I'm sorry, your page was not found.</v-card-title>
    {{ ourRoute }}
    </v-card>
</template>

<script>
export default {
  computed: {
    ourRoute() {
      console.log(this.$route);
      // We will see what `path` is shortly
      return this.$route.path;
    },
  },
};
</script>