<template>
  <v-card elevation="2" outlined tile class="mx-auto my-4 px-4" max-width="600">
    <v-card-title class="font-weight-bold">
      Please schedule a test at the most convenient time and location for you.
    </v-card-title>
    <v-card-text
      >Two testing sites are available for your convenience. Please scroll down
      to see both sites and times available.</v-card-text
    >
    <v-card-title class="font-weight-medium"
      >Natrona County Health Department</v-card-title
    >
    <v-card-text>475 S Spruce St.</v-card-text>

    <v-radio-group
      v-model="appointment"
      class="pl-4"
      v-for="(schedule, index) in schedules1"
      :key="index"
    >
      <v-card>
        <Schedule v-bind:schedule="schedule" v-bind:index="index"></Schedule>
      </v-card>
    </v-radio-group>

    <v-card-title class="font-weight-medium"
      >Casper College Fire Science Training Center</v-card-title
    >
    <v-card-text>800 College Dr.</v-card-text>
    <v-radio-group
      v-model="appointment"
      class="pl-4"
      v-for="(schedule, index) in schedules2"
      :key="index"
    >
      <v-card>
        <Schedule
          v-bind:schedule="schedule"
          v-bind:index="index + 99"
        ></Schedule>
      </v-card>
    </v-radio-group>

    <v-card-actions class="justify-center">
      <v-btn color="primary" class="ma-4 main-btn" v-on:click="navigatePrevious"
        >Previous</v-btn
      >
      <v-btn
        color="primary"
        class="ma-4 main-btn"
        :disabled="!appointment"
        v-on:click="navigateNext"
        >Next
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Schedule from "../components/Schedule";
export default {
  components: {
    Schedule,
  },
  computed: {
    appointment: {
      get() {
        return this.$store.state.appointment;
      },
      set(value) {
        this.$store.commit("setAppointment", { appointment: value });
      },
    },
    schedules1: {
      get() {
        return this.$store.state.schedules1;
      },
    },
    schedules2: {
      get() {
        return this.$store.state.schedules2;
      },
    },
  },
  methods: {
    navigatePrevious() {
      this.$router.push("/8");
    },
    navigateNext() {
      this.$router.push("/confirm");
    },
  },
};
</script>