<template>
  <v-card elevation="2" outlined tile class="mx-auto my-4 px-4" max-width="600">
    <v-form v-model="valid">
      <v-container>
        <v-card-title class="font-weight-bold">Patient Info</v-card-title>
        <v-row>
          <v-col cols="12" lg="6" sm="12">
            <v-text-field
              type="input"
              name="firstName"
              value=""
              id="firstName"
              v-model="firstName"
              :label="'First Name'"
            />
          </v-col>
          <v-col cols="12" lg="6" sm="12">
            <v-text-field
              type="input"
              name="lastName"
              value=""
              id="lastName"
              v-model="lastName"
              :label="'Last Name'"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" lg="6" sm="12">
            <v-text-field
              type="date"
              name="dob"
              id="dob"
              v-model="dob"
              :label="'Date of Birth'"
            />
          </v-col>
          <v-col cols="12" lg="6" sm="12">
            <v-text-field
              type="input"
              name="physicalAddress"
              value=""
              id="physicalAddress"
              v-model="physicalAddress"
              :label="'Physical Address'"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="4" sm="12">
            <v-text-field
              type="input"
              name="city"
              value=""
              id="city"
              v-model="city"
              :label="'City'"
            />
          </v-col>
          <v-col cols="12" lg="4" sm="12">
            <v-select
              name="state"
              id="state"
              v-model="state"
              label="State"
              :items="stateOptions"
            >
            </v-select>
          </v-col>
          <v-col cols="12" lg="4" sm="12">
            <v-text-field
              type="input"
              name="zipcode"
              value=""
              id="zipcode"
              v-model="zipcode"
              :label="'Zipcode'"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" lg="4" sm="12">
            <v-select
              type="input"
              name="county"
              value=""
              id="county"
              v-model="county"
              :label="'County'"
              :items="countyOptions"
            />
          </v-col>
          <v-col cols="12" lg="4" sm="12">
            <v-text-field
              type="phone"
              name="phone"
              value=""
              id="phone"
              v-model="phone"
              :label="'Phone'"
            />
          </v-col>
          <v-col cols="12" lg="4" sm="12">
            <v-select
              name="genderIdentity"
              id="genderIdentity"
              v-model="genderIdentity"
              label="Gender"
              :items="genderOptions"
            >
            </v-select>
            <v-text-field
              name="genderOther"
              id="genderOther"
              v-model="genderOther"
              v-if="genderIdentity == 'Other'"
              :label="'Other Gender'"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" lg="6" sm="12">
            <v-select v-model="race" :items="races" label="Race"></v-select>
          </v-col>
          <v-col cols="12" lg="4" sm="12">
            <v-card-text>Ethnicity</v-card-text>
            <v-radio-group v-model="ethnicity" class="pl-4">
              <v-radio
                type="radio"
                name="ethnicity"
                value="Hispanic"
                id="ethnicity1"
                :label="'Hispanic or Latino'"
              />

              <v-radio
                type="radio"
                name="ethnicity"
                value="Non-Hispanic"
                id="ethnicity2"
                :label="'Not Hispanic or Latino'"
              />
            </v-radio-group>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-card-actions class="justify-center">
      <v-btn color="primary" class="ma-4 main-btn" v-on:click="navigatePrevious"
        >Previous</v-btn
      >
      <v-btn
        color="primary"
        class="ma-4 main-btn"
        :disabled="
          !firstName ||
          !lastName ||
          !dob ||
          !city ||
          !physicalAddress ||
          !zipcode ||
          !state ||
          !county ||
          !phone ||
          !genderIdentity ||
          !race ||
          !ethnicity
        "
        v-on:click="navigateNext"
      >
        Next
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data: function () {
    return {
      races: [
        "American Indian or Alaska Native",
        "Asian",
        "Black or African American",
        "Native Hawaiian or Other Pacific Islander",
        "White",
      ],
      countyOptions: [
        "Albany",
        "Big Horn",
        "Campbell",
        "Carbon",
        "Converse",
        "Crook",
        "Fremont",
        "Goshen",
        "Hot Springs",
        "Johnson",
        "Laramie",
        "Lincoln",
        "Natrona",
        "Niobrara",
        "Park",
        "Platte",
        "Sheridan",
        "Sublette",
        "Sweetwater",
        "Teton",
        "Uinta",
        "Washakie",
        "Weston",
      ],
      genderOptions: ["Male", "Female", "Other"],
      stateOptions: [
        "Alabama",
        "Alaska",
        "Arizona",
        "Arkansas",
        "California",
        "Colorado",
        "Connecticut",
        "Delaware",
        "District Of Columbia",
        "Florida",
        "Georgia",
        "Hawaii",
        "Idaho",
        "Illinois",
        "Indiana",
        "Iowa",
        "Kansas",
        "Kentucky",
        "Louisiana",
        "Maine",
        "Maryland",
        "Massachusetts",
        "Michigan",
        "Minnesota",
        "Mississippi",
        "Missouri",
        "Montana",
        "Nebraska",
        "Nevada",
        "New Hampshire",
        "New Jersey",
        "New Mexico",
        "New York",
        "North Carolina",
        "North Dakota",
        "Ohio",
        "Oklahoma",
        "Oregon",
        "Pennsylvania",
        "Rhode Island",
        "South Carolina",
        "South Dakota",
        "Tennessee",
        "Texas",
        "Utah",
        "Vermont",
        "Virginia",
        "Washington",
        "West Virginia",
        "Wisconsin",
        "Wyoming",
      ],
    };
  },
  computed: {
    firstName: {
      get() {
        return this.$store.state.firstName;
      },
      set(value) {
        this.$store.commit("setFirstName", { firstName: value });
      },
    },
    lastName: {
      get() {
        return this.$store.state.lastName;
      },
      set(value) {
        this.$store.commit("setLastName", { lastName: value });
      },
    },
    dob: {
      get() {
        return this.$store.state.dob;
      },
      set(value) {
        this.$store.commit("setDob", { dob: value });
      },
    },
    physicalAddress: {
      get() {
        return this.$store.state.physicalAddress;
      },
      set(value) {
        this.$store.commit("setPhysicalAddress", { physicalAddress: value });
      },
    },
    city: {
      get() {
        return this.$store.state.city;
      },
      set(value) {
        this.$store.commit("setCity", { city: value });
      },
    },
    state: {
      get() {
        return this.$store.state.state;
      },
      set(value) {
        this.$store.commit("setState", { state: value });
      },
    },
    zipcode: {
      get() {
        return this.$store.state.zipcode;
      },
      set(value) {
        this.$store.commit("setZipcode", { zipcode: value });
      },
    },
    county: {
      get() {
        return this.$store.state.county;
      },
      set(value) {
        this.$store.commit("setCounty", { county: value });
      },
    },
    phone: {
      get() {
        return this.$store.state.phone;
      },
      set(value) {
        this.$store.commit("setPhone", { phone: value });
      },
    },
    genderIdentity: {
      get() {
        return this.$store.state.genderIdentity;
      },
      set(value) {
        this.$store.commit("setGenderIdentity", { genderIdentity: value });
      },
    },
    genderOther: {
      get() {
        return this.$store.state.genderOther;
      },
      set(value) {
        this.$store.commit("setGenderOther", { genderOther: value });
      },
    },
    race: {
      get() {
        return this.$store.state.race;
      },
      set(value) {
        this.$store.commit("setRace", { race: value });
      },
    },
    ethnicity: {
      get() {
        return this.$store.state.ethnicity;
      },
      set(value) {
        this.$store.commit("setEthnicity", { ethnicity: value });
      },
    },
  },
  methods: {
    navigatePrevious() {
      this.$router.push("/5");
    },
    navigateNext() {
      this.$router.push("/8");
    },
  },
};
</script>