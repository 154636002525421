<template>
  <v-card
    elevation="2"
    outlined
    tile
    class="mx-auto my-4"
    max-width="600"
    :loading="isLoading"
  >
    <v-card-title class="font-weight-bold"
      >Do you authorize the Casper-Natrona County Health Department to contact
      you via email to discuss test results and other information related to
      your COVID-19 test?</v-card-title
    >
    <v-card-text
      >Note that our emails are unencrypted, which means that a third party may
      be able to access the information. Electing to communicate via email will
      not affect your ability to receive a free COVID-19 test.</v-card-text
    >
    <v-card-text>
      Please note results are sent automatically from the system via test and/or
      email. If you do not have or do not provide a valid email or cell phone
      number, you will not automatically receive results.
    </v-card-text>
    <v-radio-group v-model="emailConsent" class="pl-4">
      <v-radio
        type="radio"
        name="emailConsent"
        value="Yes"
        id="emailConsent1"
        :label="'I understand the risks of sending/receiving email containing protected health information. Allow unencrypted emails.'"
      />
      <v-radio
        type="radio"
        name="emailConsent"
        value="No"
        id="email8"
        :label="'Do not allow unencrypted email.'"
      />
    </v-radio-group>
    <div v-if="emailConsent == 'Yes'">
      <v-card-text>
        <v-text-field
          type="input"
          name="email"
          value=""
          id="email"
          v-model="email"
          :label="'Email'"
        />
      </v-card-text>
    </div>
    <v-card-actions class="justify-center">
      <v-btn color="primary" class="ma-4 main-btn" v-on:click="navigatePrevious"
        >Previous</v-btn
      >
      <v-btn
        color="primary"
        class="ma-4 main-btn"
        :disabled="
          !emailConsent ||
          (emailConsent == 'Yes' && !email) ||
          isLoading ||
          !appointment
        "
        v-on:click="makeAppointment"
        >Make Appointment</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from "axios";
export default {
  data: function () {
    return {
      isLoading: false,
    };
  },
  computed: {
    appointment() {
      return this.$store.state.appointment;
    },
    emailConsent: {
      get() {
        return this.$store.state.emailConsent;
      },
      set(value) {
        this.$store.commit("setEmailConsent", { emailConsent: value });
      },
    },
    email: {
      get() {
        return this.$store.state.email;
      },
      set(value) {
        this.$store.commit("setEmail", { email: value });
      },
    },
  },
  methods: {
    navigatePrevious() {
      this.$router.push("/confirm");
    },
    makeAppointment() {
      /*var postData = {};
      postData.ageRange = this.$store.state.ageRange;
      postData.answerFor = this.$store.state.answerFor;
      postData.lifeThreatening = this.$store.state.lifeThreatening;*/
      this.isLoading = true;
      var formData = new FormData();
      formData.append("appointment", JSON.stringify(this.$store.state));
      axios
        .post(
          //"https://script.google.com/macros/s/AKfycbwRyPNUkqoZEyzm1SRrGcKqtp129bTPiP2cHEGKENPH4zNEyAS_yOGqXJdP0qEi3gdA/exec",
          "https://script.google.com/macros/s/AKfycbxUX9xeYAN50ZTuAg3Fk3RnlZKSXu_o1LBdLPhXaf764HiZk8yTEuu_byhvgu4-AXkl/exec",
          formData,
          { "content-type": "application/x-www-form-urlencoded;charset=utf-8" }
        )
        .then((response) => {
          console.log(response);
          this.$router.push("/final");
        })
        .catch(function (error) {
          console.log(error);
          alert(error);
        });
    },
  },
};
</script>
//
//https://script.google.com/macros/s/AKfycbz23JEQ9h4dM0wiJPdfbdXiQ1VwBBYcHtpWJmUeY2_FSoA72Vo/exec