<template>
  <v-card elevation="2" outlined tile class="mx-auto my-4" max-width="600">
    <v-card-title class="font-weight-bold"
      >Based on your responses, the Casper-Natrona County Health Department
      recommends that you take a free COVID-19 test. Continue here to schedule.
    </v-card-title>
    <v-card-text
      >This is the best way we #slowthespread and keep our community safe.
      You’re a Casper hero.</v-card-text
    >
    <v-card-text>
      Please note results are sent automatically from the system via test and/or
      email. If you do not have or do not provide a valid email or cell phone
      number, you will not automatically receive results.
    </v-card-text>
    <v-card-text>
      By clicking "I agree", you’re agreeing to share personal information for
      the purposes of scheduling a COVID-19 testing appointment at the
      Casper-Natrona County Health Department.
    </v-card-text>
    <v-card-text>
      <v-checkbox
        v-model="disclaimer"
        value="yes"
        label="I agree "
      ></v-checkbox>
    </v-card-text>
    <v-card-actions class="justify-center">
      <v-btn color="primary" class="ma-4 main-btn" v-on:click="navigatePrevious"
        >Previous</v-btn
      >
      <v-btn
        color="primary"
        class="ma-4 main-btn"
        :disabled="!disclaimer"
        v-on:click="navigateNext"
      >
        Continue
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  computed: {
    disclaimer: {
      get() {
        return this.$store.state.disclaimer;
      },
      set(value) {
        this.$store.commit("setDisclaimer", { disclaimer: value });
      },
    },
  },
  methods: {
    navigatePrevious() {
      this.$router.push("/4");
    },
    navigateNext() {
      this.$router.push("/7");
    },
  },
};
</script>