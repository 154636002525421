import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store(
    {
        strict: process.env.NODE_ENV !== 'production',
        state: {
            ageRange: '',
            answerFor: '',
            lifeThreatening: '',
            feelingSick: '',
            closeContact: '',
            symptoms: [],
            nursingFacility: '',
            firstResponder: '',
            conditions: [],
            firstName: '',
            lastName: '',
            dob: '',
            physicalAddress: '',
            city: '',
            state: '',
            zipcode: '',
            county: '',
            phone: '',
            genderIdentity: '',
            genderOther: '',
            race: '',
            ethnicity: '',
            onsetDate: '',
            congregate: '',
            setting: '',
            preconditions: '',
            pretested: '',
            pretestedDate: '',
            pretestedMonth: '',
            pretestedYear: '',
            pretestedType: '',
            contact: '',
            contactMore: '',
            pregnant: '',
            pregnantMore: '',
            additional: '',
            appointment: '',
            schedules: [],
            schedules1: [],
            schedules2: [],
            guardian: '',
            disclaimer: '',
            emailConsent: '',
            email: '',
            employment: '',
            continuedContact: '',
            vaccStatus: '',
            secondShotDate: '',
            booster: '',
        },
        mutations: {
            setAgeRange(state, payload) {
                state.ageRange = payload.ageRange;
            },
            setAnswerFor(state, payload) {
                state.answerFor = payload.answerFor;
            },
            setLifeThreatening(state, payload) {
                state.lifeThreatening = payload.lifeThreatening;
            },
            setFeelingSick(state, payload) {
                state.feelingSick = payload.feelingSick;
            },
            setCloseContact(state, payload) {
                state.closeContact = payload.closeContact;
            },
            setSymptoms(state, payload) {
                state.symptoms = payload.symptoms;
            },
            setNursingFacility(state, payload) {
                state.nursingFacility = payload.nursingFacility;
            },
            setFirstResponder(state, payload) {
                state.firstResponder = payload.firstResponder;
            },
            setConditions(state, payload) {
                state.conditions = payload.conditions;
            },
            setFirstName(state, payload) {
                state.firstName = payload.firstName;
            },
            setLastName(state, payload) {
                state.lastName = payload.lastName;
            },
            setDob(state, payload) {
                state.dob = payload.dob;
            },
            setPhysicalAddress(state, payload) {
                state.physicalAddress = payload.physicalAddress;
            },
            setCity(state, payload) {
                state.city = payload.city;
            },
            setState(state, payload) {
                state.state = payload.state;
            },
            setZipcode(state, payload) {
                state.zipcode = payload.zipcode;
            },
            setCounty(state, payload) {
                state.county = payload.county;
            },
            setPhone(state, payload) {
                state.phone = payload.phone;
            },
            setGenderIdentity(state, payload) {
                state.genderIdentity = payload.genderIdentity;
            },
            setGenderOther(state, payload) {
                state.genderOther = payload.genderOther;
            },
            setRace(state, payload) {
                state.race = payload.race;
            },
            setEthnicity(state, payload) {
                state.ethnicity = payload.ethnicity;
            },
            setOnsetDate(state, payload) {
                state.onsetDate = payload.onsetDate;
            },
            setCongregate(state, payload) {
                state.congregate = payload.congregate;
            },
            setSetting(state, payload) {
                state.setting = payload.setting;
            },
            setPreconditions(state, payload) {
                state.preconditions = payload.preconditions;
            },
            setPretested(state, payload) {
                state.pretested = payload.pretested;
            },
            setPretestedDate(state, payload) {
                state.pretestedDate = payload.pretestedDate;
            },
            setPretestedYear(state, payload) {
                state.pretestedYear = payload.pretestedYear;
            },
            setPretestedMonth(state, payload) {
                state.pretestedMonth = payload.pretestedMonth;
            },
            setPretestedType(state, payload) {
                state.pretestedType = payload.pretestedType;
            },
            setContact(state, payload) {
                state.contact = payload.contact;
            },
            setContactMore(state, payload) {
                state.contactMore = payload.contactMore;
            },
            setPregnant(state, payload) {
                state.pregnant = payload.pregnant;
            },
            setPregnantMore(state, payload) {
                state.pregnantMore = payload.pregnantMore;
            },
            setAdditional(state, payload) {
                state.additional = payload.additional;
            },
            setAppointment(state, payload) {
                state.appointment = payload.appointment;
            },
            setSchedules(state, payload) {
                state.schedule1 = payload;
            },
            setSchedules1(state, payload) {
                state.schedules1 = payload.schedule1;
            },
            setSchedules2(state, payload) {
                state.schedules2 = payload.schedule2;
            },
            setGuardian(state, payload) {
                state.guardian = payload.guardian;
            },
            setDisclaimer(state, payload) {
                state.disclaimer = payload.disclaimer;
            },
            setEmailConsent(state, payload) {
                state.emailConsent = payload.emailConsent;
            },
            setEmail(state, payload) {
                state.email = payload.email;
            },
            setEmployment(state, payload) {
                state.employment = payload.employment;
            },
            setContinuedContact(state, payload) {
                state.continuedContact = payload.continuedContact;
            },
            setVaccStatus(state, payload) {
                state.vaccStatus = payload.vaccStatus;
            },
            setSecondShotDate(state, payload) {
                state.secondShotDate = payload.secondShotDate;
            },
            setBooster(state, payload) {
                state.booster = payload.booster;
            }
        }
    }
)

export default store