import Vue from 'vue'
import router from './router'
import store from './store'
import App from './App.vue'

import VueGtm from 'vue-gtm';



import axios from 'axios'
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false

Vue.use(VueGtm, {
  id: 'GTM-NQJ5945', // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
  defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: true, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  ignoredViews: ['homepage'], // Don't trigger events for specified router names (case insensitive) (optional)
  trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
});

new Vue({
  router,
  store,
  render: h => h(App),
  vuetify,

  async mounted() {
    /* Get the staff available info then get the booked info. Compare the two in order to build the schedules.*/
    const [firstResponse, secondResponse] = await Promise.all([
      axios.get("https://script.google.com/macros/s/AKfycbyk73RU2N3kBSpeK8kCsHM8Te_E92XkhxrLxgNh4Q3tc6G3-gA/exec"),
      axios.get("https://script.google.com/macros/s/AKfycbz23JEQ9h4dM0wiJPdfbdXiQ1VwBBYcHtpWJmUeY2_FSoA72Vo/exec"),
    ]);

    var tSchedule1 = [];
    var tSchedule2 = [];
    var offSchedul1 = [];
    var offSchedul2 = [];

    //Health Dep
    for (var i = 0; i < firstResponse.data[0].length; i++) {
      tSchedule1.push(firstResponse.data[0][i][0]);
    }
    //Fire Science
    for (i = 0; i < firstResponse.data[1].length; i++) {
      tSchedule2.push(firstResponse.data[1][i][0]);
    }
    //Health Dept booked sched
    for (i = 0; i < secondResponse.data[0].length; i++) {
      offSchedul1.push(secondResponse.data[0][i][0]);
    }
    //Fire Science booked sched
    for (i = 0; i < secondResponse.data[1].length; i++) {
      offSchedul2.push(secondResponse.data[1][i][0]);
    }

    var schedule1 = [];
    var schedule2 = [];
    for (i = 0; i < tSchedule1.length; i++) {
      if (!offSchedul1.includes(tSchedule1[i])) {
        schedule1.push(tSchedule1[i]);
      }
    }
    for (i = 0; i < tSchedule2.length; i++) {
      if (!offSchedul2.includes(tSchedule2[i])) {
        schedule2.push(tSchedule2[i]);
      }
    }

    store.commit('setSchedules1', { schedule1 });
    store.commit('setSchedules2', { schedule2 });

  }
}).$mount('#app')
