<template>
  <v-card elevation="2" outlined tile class="mx-auto my-4 px-4" max-width="600">
    <v-card-title class="font-weight-bold">Screening Info</v-card-title>
    <v-form v-model="valid">
      <v-container>
        <v-row>
          <v-col cols="12" sm="12">
            <v-text-field
              type="date"
              name="onsetDate"
              id="onsetDate"
              v-model="onsetDate"
              :label="'Onset Date of Symptoms or Date of Exposure'"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12">
            <v-text-field
              name="employment"
              id="employment"
              v-model="employment"
              :label="'Place of employment?'"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-card-text
            >Do you have continued close contact with persons 65 and older with
            underlying health conditions (diabetes, heart disease, lung disease
            or immunocompromised)?</v-card-text
          >
          <v-radio-group v-model="continuedContact" class="pl-4">
            <v-radio
              type="radio"
              name="continuedContact"
              value="Yes"
              id="continuedContact1"
              :label="'Yes'"
            />
            <v-radio
              type="radio"
              name="continuedContact"
              value="No"
              id="continuedContact2"
              :label="'No'"
            />
          </v-radio-group>
        </v-row>
        <v-row>
          <v-card-text
            >Do you live or work in a congregate setting (an environment
            multiple families or living units gather in one building; i.e. dorm,
            nursing home, shelter)?</v-card-text
          >
          <v-radio-group v-model="congregate" class="pl-4">
            <v-radio
              type="radio"
              name="congregate"
              value="Yes"
              id="congregate1"
              :label="'Yes'"
            />
            <v-radio
              type="radio"
              name="congregate"
              value="No"
              id="congregate2"
              :label="'No'"
            />
          </v-radio-group>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12">
            <v-text-field
              type="input"
              name="setting"
              id="setting"
              v-model="setting"
              v-if="congregate == 'Yes'"
              :label="'Type of Setting'"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-card-text
            >Do you have any preexisting health conditions?</v-card-text
          >
          <v-radio-group v-model="preconditionsOption" class="pl-4">
            <v-radio
              type="radio"
              name="preconditionsOption"
              value="Yes"
              id="preconditionOption1"
              :label="'Yes'"
            />
            <v-radio
              type="radio"
              name="preconditionsOption"
              value="No"
              id="preconditionOption1"
              :label="'No'"
            />
          </v-radio-group>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12">
            <v-textarea
              type="text"
              name="preconditions"
              id="preconditions"
              v-model="preconditions"
              v-if="preconditionsOption == 'Yes'"
              :label="'Please list any pre-existing conditions such as Diabetes, chronic kindey disease, liver disease, etc.'"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-card-text>Have you ever been tested for COVID before?</v-card-text>
          <v-radio-group v-model="pretested" class="pl-4">
            <v-radio
              type="radio"
              name="pretested"
              value="Yes"
              id="pretested1"
              :label="'Yes'"
            />
            <v-radio
              type="radio"
              name="pretested"
              value="No"
              id="pretested2"
              :label="'No'"
            />
          </v-radio-group>
        </v-row>

        <v-row>
          <v-col cols="12" lg="6" sm="12">
            <v-select
              v-model="pretestedMonth"
              v-if="pretested == 'Yes'"
              :items="months"
              label="Month of Last Test"
            ></v-select>
          </v-col>
          <v-col cols="12" lg="6" sm="12">
            <v-select
              v-model="pretestedType"
              v-if="pretested == 'Yes'"
              :items="pretestedItems"
              label="Type of Test"
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-card-text>Are you Pregnant?</v-card-text>
          <v-radio-group v-model="pregnant" class="pl-4">
            <v-radio
              type="radio"
              name="pregnant"
              value="Yes"
              id="pregnant1"
              :label="'Yes'"
            />
            <v-radio
              type="radio"
              name="pregnant"
              value="No"
              id="pregnant2"
              :label="'No'"
            />
          </v-radio-group>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12">
            <v-textarea
              type="text"
              name="pregnantMore"
              id="pregnantMore"
              v-model="pregnantMore"
              v-if="pregnant == 'Yes'"
              :label="'Describe your pregnancy including due date and any complications:'"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12">
            <v-textarea
              name="additional"
              id="additional"
              v-model="additional"
              :label="'Additional Info:'"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-card-actions class="justify-center">
      <v-btn color="primary" class="ma-4 main-btn" v-on:click="navigatePrevious"
        >Previous</v-btn
      >
      <v-btn
        color="primary"
        class="ma-4 main-btn"
        :disabled="
          !onsetDate ||
          !congregate ||
          !pretested ||
          !pregnant ||
          !continuedContact
        "
        v-on:click="navigateNext"
      >
        Next
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data: function () {
    return {
      pretestedItems: ["Rapid results test", "Delayed results test"],
      preconditionsOption: "",
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
    };
  },
  computed: {
    onsetDate: {
      get() {
        return this.$store.state.onsetDate;
      },
      set(value) {
        this.$store.commit("setOnsetDate", { onsetDate: value });
      },
    },
    congregate: {
      get() {
        return this.$store.state.congregate;
      },
      set(value) {
        this.$store.commit("setCongregate", { congregate: value });
      },
    },
    setting: {
      get() {
        return this.$store.state.setting;
      },
      set(value) {
        this.$store.commit("setSetting", { setting: value });
      },
    },
    preconditions: {
      get() {
        return this.$store.state.preconditions;
      },
      set(value) {
        this.$store.commit("setPreconditions", { preconditions: value });
      },
    },
    pretested: {
      get() {
        return this.$store.state.pretested;
      },
      set(value) {
        this.$store.commit("setPretested", { pretested: value });
      },
    },
    pretestedDate: {
      get() {
        return this.$store.state.pretestedDate;
      },
      set(value) {
        this.$store.commit("setPretestedDate", { pretestedDate: value });
      },
    },
    pretestedMonth: {
      get() {
        return this.$store.state.pretestedMonth;
      },
      set(value) {
        this.$store.commit("setPretestedMonth", { pretestedMonth: value });
      },
    },
    pretestedYear: {
      get() {
        return this.$store.state.pretestedYear;
      },
      set(value) {
        this.$store.commit("setPretestedYear", { pretestedYear: value });
      },
    },
    pretestedType: {
      get() {
        return this.$store.state.pretestedType;
      },
      set(value) {
        this.$store.commit("setPretestedType", { pretestedType: value });
      },
    },
    contact: {
      get() {
        return this.$store.state.contact;
      },
      set(value) {
        this.$store.commit("setContact", { contact: value });
      },
    },
    contactMore: {
      get() {
        return this.$store.state.contactMore;
      },
      set(value) {
        this.$store.commit("setContactMore", { contactMore: value });
      },
    },
    pregnant: {
      get() {
        return this.$store.state.pregnant;
      },
      set(value) {
        this.$store.commit("setPregnant", { pregnant: value });
      },
    },
    pregnantMore: {
      get() {
        return this.$store.state.pregnantMore;
      },
      set(value) {
        this.$store.commit("setPregnantMore", { pregnantMore: value });
      },
    },
    additional: {
      get() {
        return this.$store.state.additional;
      },
      set(value) {
        this.$store.commit("setAdditional", { additional: value });
      },
    },
    continuedContact: {
      get() {
        return this.$store.state.continuedContact;
      },
      set(value) {
        this.$store.commit("setContinuedContact", { continuedContact: value });
      },
    },
    employment: {
      get() {
        return this.$store.state.employment;
      },
      set(value) {
        this.$store.commit("setEmployment", { employment: value });
      },
    },
  },
  methods: {
    navigatePrevious() {
      this.$router.push("/7");
    },
    navigateNext() {
      this.$router.push("/9");
      window.scrollTo(0, 0);
    },
  },
};
</script>