<template>
  <v-card elevation="2" outlined tile class="mx-auto my-4" max-width="600">
    <v-card-title class="font-weight-bold">IMPORTANT </v-card-title>
    <v-card-text>
      If you need a mandatory PCR test for either travel or elective reasons,
      please make an appointment by calling
      <a href="tel:307.577.9892">307.577.9892</a>. These tests can only be
      scheduled over the phone.
    </v-card-text>
    <v-card-actions class="justify-center">
      <v-btn color="primary" class="ma-4 main-btn" v-on:click="navigatePrevious"
        >Previous</v-btn
      >
      <v-btn color="primary" class="ma-4 main-btn" v-on:click="navigateNext">
        Proceed to Symptomatic or Exposure Testing
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  computed: {
    disclaimer: {
      get() {
        return this.$store.state.disclaimer;
      },
      set(value) {
        this.$store.commit("setDisclaimer", { disclaimer: value });
      },
    },
  },
  methods: {
    navigatePrevious() {
      this.$router.push("/");
    },
    navigateNext() {
      this.$router.push("/1");
    },
  },
};
</script>