import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/Home'
import Step1 from '../components/AgeRange'
import Step2 from '../components/LifeThreatening'
import Step3 from '../components/Symptoms'
import Step4 from '../components/Exposure'
import Step5 from '../components/Disclaimer'
import Step6 from '../components/Multiple'
import Step7 from '../components/PatientStep1'
import Step8 from '../components/PatientStep2'
import Step9 from '../components/ScheduleStep1'
import Step10 from '../components/VaccinationStatus'
import Step11 from '../components/PcrTest'

import Confirm from '../components/Confirmation'
import Email from '../components/Email'
import Final from '../components/Final'
import EligibilityEnd from '../components/NotEligible'
import Emergency from '../components/Emergency'
import EarlyEnd from '../components/EarlyEnd'
import NotFound from '../components/NotFound'
import Call from '../components/Call'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'home', component: Home },
  { path: '/1', name: '1', component: Step1 },
  { path: '/2', name: '2', component: Step2 },
  { path: '/3', name: '3', component: Step3 },
  { path: '/4', name: '4', component: Step4 },
  { path: '/5', name: '5', component: Step5 },
  { path: '/6', name: '6', component: Step6 },
  { path: '/7', name: '7', component: Step7 },
  { path: '/8', name: '8', component: Step8 },
  { path: '/9', name: '9', component: Step9 },
  { path: '/vacc', name: 'vacc', component: Step10 },
  { path: '/pcr', name: 'pcr', component: Step11 },
  { path: '/confirm', name: 'confirm', component: Confirm },
  { path: '/email', name: 'email', component: Email },
  { path: '/final', name: 'final', component: Final },
  { path: '/emergency', name: 'emergencyEnd', component: Emergency },
  { path: '/eligibility', name: 'eligibility', component: EligibilityEnd },
  { path: '/99', name: 'earlyEnd', component: EarlyEnd },
  { path: '/call', name: 'call', component: Call },
  { path: '*', name: 'notfound', component: NotFound },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
