<template>
  <v-card elevation="2" outlined tile class="mx-auto my-4" max-width="600">
    <v-card-title class="font-weight-bold">
      Have you been vaccinated for COVID-19?
    </v-card-title>
    <v-radio-group v-model="vaccStatus" class="pl-4">
      <v-radio
        type="radio"
        name="vaccStatus"
        value="Fully Vaccinated"
        id="vaccStatus1"
      >
        <template v-slot:label>
          <p>
            I am fully vaccinated.
            <em
              >(Fully vaccinated is two weeks since your second Pfizer or
              Moderna vaccination or two weeks since your single dose Johnson &
              Johnson).</em
            >
          </p>
        </template>
      </v-radio>
      <v-row v-if="vaccStatus == 'Fully Vaccinated'">
        <v-col cols="12" lg="8" sm="12" class="ml-12">
          <v-text-field
            type="date"
            name="secondShotDate"
            id="secondShotDate"
            v-model="secondShotDate"
            :label="'Date of second vaccination (to the best of your memory)'"
          />
        </v-col>
      </v-row>
      <v-row v-if="vaccStatus == 'Fully Vaccinated'">
        <v-col cols="12" lg="6" sm="12" class="ml-12">
          <v-checkbox
            type="checkbox"
            nname="booster"
            value="Yes"
            id="booster"
            v-model="booster"
            :label="'I have received a booster shot.'"
          />
        </v-col>
      </v-row>
      <v-radio
        type="radio"
        name="vaccStatus"
        value="Partially Vaccinated"
        id="vaccStatus2"
      >
        <template v-slot:label>
          <p>
            I am partially vaccinated.
            <em
              >(You have only received one dose of Pfizer or Moderna or two
              weeks has not yet lapsed since your second dose or your single
              dose of Johnson & Johnson.)</em
            >
          </p>
        </template>
      </v-radio>
      <v-radio
        type="radio"
        name="vaccStatus"
        value="Not Vaccinated"
        id="vaccStatus3"
        :label="'I have not received any COVID vaccine.'"
      />
    </v-radio-group>
    <v-card-actions class="justify-center">
      <v-btn color="primary" class="ma-4 main-btn" v-on:click="navigatePrevious"
        >Previous</v-btn
      >
      <v-btn
        color="primary"
        class="ma-4 main-btn"
        :disabled="!vaccStatus"
        v-on:click="navigateNext"
        >Next</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  computed: {
    vaccStatus: {
      get() {
        return this.$store.state.vaccStatus;
      },
      set(value) {
        this.$store.commit("setVaccStatus", { vaccStatus: value });
      },
    },
    secondShotDate: {
      get() {
        return this.$store.state.secondShotDate;
      },
      set(value) {
        this.$store.commit("setSecondShotDate", { secondShotDate: value });
      },
    },
    booster: {
      get() {
        return this.$store.state.booster;
      },
      set(value) {
        this.$store.commit("setBooster", { booster: value });
      },
    },
  },
  methods: {
    navigatePrevious() {
      this.$router.push("/1");
    },
    navigateNext() {
      this.$router.push("/2");
    },
  },
};
</script>

<style scoped>
em {
  font-size: 0.8rem;
}
</style>