<template>
  <v-card elevation="2" outlined tile class="mx-auto my-4" max-width="600">
    <v-card-title class="font-weight-bold"
      >Are there multiple people living in your household who need
      testing?</v-card-title
    >
    <v-card-actions class="justify-center">
      <v-btn color="primary" class="ma-4 main-btn" v-on:click="navigatePrevious"
        >Yes</v-btn
      >
      <v-btn color="primary" class="ma-4 main-btn" v-on:click="navigateNext">
        Nope, Just Me
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  methods: {
    navigatePrevious() {
      this.$router.push("/call");
    },
    navigateNext() {
      this.$router.push("/7");
    },
  },
};
</script>