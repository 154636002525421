<template>
  <v-card elevation="2" outlined tile class="mx-auto my-4" max-width="600">
    <v-card-title class="font-weight-bold"
      >Please Call Our Testing Hotline</v-card-title
    >
    <v-card-text
      >Please call our testing hotline at
      <a href="tel:+13075779892">307.577.9892</a> so we can get everyone
      scheduled in one convenient time.</v-card-text
    >
    <v-card-actions class="justify-center">
      <v-btn color="primary" class="ma-4 main-btn" v-on:click="openPhone"
        >Call</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  methods: {
    openPhone() {
      window.open("tel:+13075779892");
    },
  },
};
</script>