<template>
  <v-card elevation="2" outlined tile class="mx-auto my-4" max-width="600">
    <v-card-title class="font-weight-bold"
      >Do you have any of these life-threatening symptoms?</v-card-title
    >

    <ul class="pl-8">
      <li>Bluish lips or face</li>
      <li>Severe and constant pain or pressure in the chest</li>
      <li>
        Extreme difficulty breathing (such as gasping for air, being unable to
        talk without catching their breath, severe wheezing, nostrils flaring)
      </li>
      <li>New disorientation (acting confused)</li>
      <li>Unconscious or very difficult to wake up</li>
      <li>Slurred speech or difficulty speaking (new or worsening)</li>
      <li>New or worsening seizures</li>
      <li>
        Signs of low blood pressure (too weak to stand, dizziness, lightheaded,
        feeling cold, pale, clammy skin)
      </li>
      <li>Dehydration (dry lips and mouth, not urinating much, sunken eyes)</li>
    </ul>
    <v-radio-group v-model="lifeThreatening" class="pl-4">
      <v-radio
        type="radio"
        name="lifeThreatening"
        value="yes"
        id="lifeThreatening1"
        :label="'Yes'"
      />
      <v-radio
        type="radio"
        name="lifeThreatening"
        value="no"
        id="lifeThreatening2"
        :label="'No'"
      />
    </v-radio-group>
    <v-card-actions class="justify-center">
      <v-btn color="primary" class="ma-4 main-btn" v-on:click="navigatePrevious"
        >Previous</v-btn
      >
      <v-btn
        color="primary"
        class="ma-4 main-btn"
        :disabled="!lifeThreatening"
        v-on:click="navigateNext"
        >Next</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  computed: {
    lifeThreatening: {
      get() {
        return this.$store.state.lifeThreatening;
      },
      set(value) {
        this.$store.commit("setLifeThreatening", { lifeThreatening: value });
      },
    },
  },
  methods: {
    navigateNext() {
      if (this.$store.state.lifeThreatening == "yes") {
        this.$router.push("/emergency");
      } else {
        this.$router.push("/3");
      }
    },
    navigatePrevious() {
      this.$router.push("/vacc");
    },
  },
};
</script>