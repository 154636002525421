<template>
  <v-app>
    <v-main>
      <v-row>
        <v-img
          :src="require('@/assets/Casper-V-Covid-logo.png')"
          contain
          height="300"
          sm-height="100px"
        />
      </v-row>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  components: {},

  data: () => ({
    //
  }),
};
</script>

<style>
@media screen and (max-width: 39.9375em) {
  .v-image {
    height: 200px !important;
  }
}
</style>
