<template>
  <v-card elevation="2" outlined tile class="mx-auto my-4" max-width="600">
    <v-card-title class="font-weight-bold">
      In the last two weeks, did you have close contact (within 6 feet for at
      least 15 minutes) with someone who exhibited COVID-19 symptoms, was tested
      for COVID-19 or was diagnosed with COVID-19?
    </v-card-title>
    <v-radio-group v-model="closeContact" class="pl-4">
      <v-radio
        type="radio"
        name="closeContact"
        value="Yes"
        id="closeContact1"
        :label="'Yes'"
      />
      <v-radio
        type="radio"
        name="closeContact"
        value="No"
        id="closeContact2"
        :label="'No'"
      />
      <v-radio
        type="radio"
        name="closeContact"
        value="I don't know"
        id="closeContact2"
        :label="'I don\'t know'"
      />
    </v-radio-group>
    <v-card-actions class="justify-center">
      <v-btn color="primary" class="ma-4 main-btn" v-on:click="navigatePrevious"
        >Previous</v-btn
      >
      <v-btn
        color="primary"
        class="ma-4 main-btn"
        :disabled="!closeContact"
        v-on:click="navigateNext"
        >Next</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  computed: {
    closeContact: {
      get() {
        return this.$store.state.closeContact;
      },
      set(value) {
        this.$store.commit("setCloseContact", { closeContact: value });
      },
    },
  },
  methods: {
    navigatePrevious() {
      this.$router.push("/3");
    },
    navigateNext() {
      if (
        (!this.$store.state.symptoms ||
          this.$store.state.symptoms.includes("No Symptoms")) &&
        this.$store.state.closeContact != "Yes"
      ) {
        this.$router.push("/eligibility");
      } else {
        this.$router.push("/5");
      }
    },
  },
};
</script>