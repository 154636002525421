<template>
    <v-card elevation="2"
  outlined
  tile
  class="mx-auto my-4"
  max-width="600"
  >
    <v-card-title class="font-weight-bold">Terms Error</v-card-title>
    <v-card-text>Please agree to the terms to continue.</v-card-text>
    </v-card>
</template>

<script>
export default {};
</script>