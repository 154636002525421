<template>
    <v-container class="mb-2 pl-0">
      <v-radio
        name="appointment"
        :value="schedule"
        >
<template v-slot:label>
           <v-card-title>{{ schedDate }}<br />{{ schedDay }}<br />{{ schedTime }}</v-card-title>
        </template>
      </v-radio>
    </v-container>
</template>

<script>
export default {
  props: ["schedule", "index"],
  computed: {
    appointment: {
      get() {
        return this.$store.state.appointment;
      },
      set(value) {
        this.$store.commit("setAppointment", { appointment: value });
      },
    },
    schedDay: function () {
      var info = this.schedule.split("|");
      return info[1];
    },
    schedDate: function () {
      var info = this.schedule.split("|");
      return info[2];
    },
    schedTime: function () {
      var info = this.schedule.split("|");
      return info[3];
    },
    idIndex: function () {
      return "schedule" + this.index;
    },
  },
};
</script>

<style scoped>
.container{
  width: 265px;
}
@media screen and (max-width: 39.9375em) {
.container{
  width: 300px;
}
}

</style>