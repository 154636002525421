<template>
  <v-card elevation="2" outlined tile class="mx-auto my-4" max-width="600">
    <v-card-title class="font-weight-bold">What is your age?</v-card-title>
    <v-radio-group v-model="ageRange" class="pl-4">
      <v-radio
        type="radio"
        name="ageRange"
        value="17"
        id="ageRange1"
        :label="'Under 17'"
      />
      <v-radio
        type="radio"
        name="ageRange"
        value="18-29"
        id="ageRange1"
        :label="'18-29'"
      />
      <v-radio
        type="radio"
        name="ageRange"
        value="30-39"
        id="ageRange2"
        :label="'30-39'"
      />
      <v-radio
        type="radio"
        name="ageRange"
        value="40-49"
        id="ageRange3"
        :label="'40-49'"
      />
      <v-radio
        type="radio"
        name="ageRange"
        value="50-59"
        id="ageRange4"
        :label="'50-59'"
      />
      <v-radio
        type="radio"
        name="ageRange"
        value="60-64"
        id="ageRange5"
        :label="'60-64'"
      />
      <v-radio
        type="radio"
        name="ageRange"
        value="65-69"
        id="ageRange6"
        :label="'65-69'"
      />
      <v-radio
        type="radio"
        name="ageRange"
        value="70-79"
        id="ageRange7"
        :label="'70-79'"
      />
      <v-radio
        type="radio"
        name="ageRange"
        value="80+"
        id="ageRange8"
        :label="'80+'"
      />
    </v-radio-group>
    <v-card-text>
      <div v-if="ageRange == 17">
        <p>
          By checking this box, you are verifying that you are a parent or
          guardian over the age of 18 who is completing this form on your
          minor’s behalf.
        </p>
        <v-checkbox v-model="guardian" value="yes" label="I agree"></v-checkbox>
      </div>
    </v-card-text>
    <v-card-actions class="justify-center">
      <v-btn color="primary" class="ma-4 main-btn" v-on:click="navigatePrevious"
        >Previous</v-btn
      >
      <v-btn
        color="primary"
        class="ma-4 main-btn"
        :disabled="!ageRange || (ageRange == '17' && !guardian)"
        v-on:click="navigateNext"
        >Next</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  computed: {
    guardian: {
      get() {
        return this.$store.state.guardian;
      },
      set(value) {
        this.$store.commit("setGuardian", { guardian: value });
      },
    },
    ageRange: {
      get() {
        return this.$store.state.ageRange;
      },
      set(value) {
        this.$store.commit("setAgeRange", { ageRange: value });
      },
    },
  },
  methods: {
    navigateNext() {
      if (this.$store.state.lifeThreatening == "yes") {
        this.$router.push("/emergency");
      } else {
        this.$router.push("/vacc");
      }
    },
    navigatePrevious() {
      this.$router.push("/pcr");
    },
  },
};
</script>