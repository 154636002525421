<template>
  <v-card
    elevation="2"
    outlined
    tile
    class="mx-auto my-4"
    max-width="600"
    :loading="isLoading"
  >
    <v-card-title class="font-weight-bold"
      >Confirm Your Appointment</v-card-title
    >
    <v-card-text class="font-weight-medium">
      {{ name }}<br />
      {{ location }}<br />
      {{ appointmentDay }}, {{ appointmentDate }}<br />
      {{ appointmentTime }}</v-card-text
    >
    <v-card-text v-if="locationHD">
      When you come for your appointment, please go to the alley on the east
      side of the building and park under the tent. For the safety of our staff
      and visitors, please DO NOT enter the building.
    </v-card-text>
    <v-card-actions class="justify-center">
      <v-btn color="primary" class="ma-4 main-btn" v-on:click="navigatePrevious"
        >Previous</v-btn
      >
      <v-btn color="primary" class="ma-4 main-btn" v-on:click="makeAppointment">
        Confirm Appointment
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data: function () {
    return {
      isLoading: false,
    };
  },
  computed: {
    name() {
      return this.$store.state.firstName + " " + this.$store.state.lastName;
    },
    appointment() {
      return this.$store.state.appointment;
    },
    locationHD() {
      var appt = this.$store.state.appointment.split("|");
      if (appt[0] == "FS") {
        return false;
      }
      return true;
    },
    location() {
      var appt = this.$store.state.appointment.split("|");
      if (appt[0] == "FS") {
        return "Casper College Fire Science Training Center, 800 College Dr.";
      } else {
        return "Natrona County Health Department, 475 S Spruce St.";
      }
    },
    appointmentDay() {
      var appt = this.$store.state.appointment.split("|");
      return appt[1];
    },
    appointmentDate() {
      var appt = this.$store.state.appointment.split("|");
      return appt[2];
    },
    appointmentTime() {
      var appt = this.$store.state.appointment.split("|");
      return appt[3];
    },
  },
  methods: {
    navigatePrevious() {
      this.$router.push("/9");
    },
    makeAppointment() {
      this.$router.push("/email");
    },
  },
};
</script>