<template>
  <v-card elevation="2" outlined tile class="mx-auto my-4" max-width="600">
    <v-card-title class="font-weight-bold"
      >You're all set for your free COVID-19 Test.</v-card-title
    >
    <v-card-text>
      Until then, please quarantine at home. <br /><br />You’re sacrificing for
      the safety and wellbeing of your community, and on behalf of all of
      Casper, thank you. In the meantime, here’s ten hours of cute cat videos to
      keep you company:
      <a href="https://www.youtube.com/watch?v=FfB7Kuq9lSc" target="_blank"
        >https://www.youtube.com/watch?v=FfB7Kuq9lSc</a
      >.
    </v-card-text>
    <v-card-text>
      If you have more people who need a test, click the button below to
      complete their forms.
    </v-card-text>
    <v-card-actions class="justify-center">
      <v-btn color="primary" class="ma-4 main-btn" v-on:click="navigateNext"
        >Test More People
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  computed: {
    location: {
      get() {
        return this.$store.state.appointment.substring(0, 2);
      },
    },
  },
  methods: {
    navigateNext() {
      localStorage.clear();
      this.$router.push("/");
      location.reload();
    },
  },
};
</script>