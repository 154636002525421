<template>
  <v-card elevation="2" outlined tile class="mx-auto my-4" max-width="600">
    <v-card-title class="font-weight-bold"
      >Do you have any of the following symptoms?</v-card-title
    >
    <v-card-text>Please check all that apply.</v-card-text>
    <v-container class="pl-4">
      <v-checkbox
        type="checkbox"
        nname="symptoms[]"
        value="Fever or feeling feverish (such as chills, sweating)"
        id="symptoms1"
        v-model="symptoms"
        :label="'Fever or feeling feverish (such as chills, sweating)'"
        :disabled="hasNoSymptoms"
      />
      <v-checkbox
        type="checkbox"
        nname="symptoms[]"
        value="Cough"
        id="symptoms2"
        v-model="symptoms"
        :label="'Cough'"
        :disabled="hasNoSymptoms"
      />
      <v-checkbox
        type="checkbox"
        nname="symptoms[]"
        value="Mild or moderate difficulty breathing"
        id="symptoms3"
        v-model="symptoms"
        :label="'Mild or moderate difficulty breathing'"
        :disabled="hasNoSymptoms"
      />
      <v-checkbox
        type="checkbox"
        nname="symptoms[]"
        value="Sore throat"
        id="symptoms4"
        v-model="symptoms"
        :label="'Sore throat'"
        :disabled="hasNoSymptoms"
      />
      <v-checkbox
        type="checkbox"
        nname="symptoms[]"
        value="Muscle aches or body aches"
        id="symptoms5"
        v-model="symptoms"
        :label="'Muscle aches or body aches'"
        :disabled="hasNoSymptoms"
      />
      <v-checkbox
        type="checkbox"
        nname="symptoms[]"
        value="Vomiting or diarrhea"
        id="symptoms6"
        v-model="symptoms"
        :label="'Vomiting or diarrhea'"
        :disabled="hasNoSymptoms"
      />
      <v-checkbox
        type="checkbox"
        nname="symptoms[]"
        value="New loss of taste or smell"
        id="symptoms7"
        v-model="symptoms"
        :label="'New loss of taste or smell'"
        :disabled="hasNoSymptoms"
      />
      <v-checkbox
        type="checkbox"
        nname="symptoms[]"
        value="Congestion or runny nose"
        id="symptoms8"
        v-model="symptoms"
        :label="'Congestion or runny nose'"
        :disabled="hasNoSymptoms"
      />
      <v-checkbox
        type="checkbox"
        nname="symptoms[]"
        value="Other symptoms"
        id="symptoms9"
        v-model="symptoms"
        :label="'Other symptoms'"
        :disabled="hasNoSymptoms"
      />
      <v-checkbox
        type="checkbox"
        nname="symptoms[]"
        value="No Symptoms"
        id="symptoms9"
        v-model="symptoms"
        :label="'No symptoms'"
      />
    </v-container>
    <v-card-actions class="justify-center">
      <v-btn color="primary" class="ma-4 main-btn" v-on:click="navigatePrevious"
        >Previous</v-btn
      >
      <v-btn
        color="primary"
        class="ma-4 main-btn"
        :disabled="!symptoms || symptoms.length == 0"
        v-on:click="navigateNext"
      >
        Next
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      hasNoSymptoms: false,
    };
  },
  computed: {
    symptoms: {
      get() {
        return this.$store.state.symptoms;
      },
      set(value) {
        this.$store.commit("setSymptoms", { symptoms: value });
        if (this.$store.state.symptoms.includes("No Symptoms")) {
          this.hasNoSymptoms = true;
        } else {
          this.hasNoSymptoms = false;
        }
      },
    },
  },
  methods: {
    navigatePrevious() {
      this.$router.push("/2");
    },
    navigateNext() {
      if (
        (this.$store.state.symptoms.length == 0 ||
          this.$store.state.symptoms[0] == "No Symptoms") &&
        this.$store.state.closeContact == "No"
      ) {
        this.$router.push("/eligibility");
      } else {
        this.$router.push("/4");
      }
    },
  },
};
</script>