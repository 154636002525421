<template>
  <v-card elevation="2" outlined tile class="mx-auto my-4" max-width="600">
    <v-card-title class="font-weight-bold">
      Due to dramatically increased volume, COVID testing policies have changed.
    </v-card-title>
    <v-card-text>
      <p>
        Tests are now only available by drive-up at our Spruce Street location
        Monday through Friday 8:30 a.m. to 4:30 p.m.
      </p>

      <p><strong>We are no longer making appointments for tests.</strong></p>

      <p>
        PCR tests are still available to anyone experiencing symptoms or who has
        been exposed.
      </p>

      <p>
        <em
          >Rapid tests are in extreme shortage nationwide and are only available
          on a very limited basis. Most individuals will not receive rapid
          tests.</em
        >
      </p>

      <p>
        Due to this surge, all travel testing is suspended. This decision will
        be re-evaluated in early February.
      </p>
    </v-card-text>
    <!--<v-card-title class="font-weight-bold">
      You’re on Team Casper
    </v-card-title>

    <v-card-text>
      <p>
        Thanks for your interest in getting tested. You’re obviously committed
        to our community’s health and wellbeing. Together, we can beat this bug.
      </p>
      <br />
      <p>By continuing, you’re agreeing to these terms.</p>
      <div class="text-center justify-center">
        <v-dialog v-model="dialog" width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="red lighten-2" dark v-bind="attrs" v-on="on">
              View Terms
            </v-btn>
          </template>

          <v-card>
            <v-card-title class="headline grey lighten-2">
              Terms of Use
            </v-card-title>

            <v-card-text>
              In consideration for services to be rendered to me by Casper
              Natrona County Health Department and its staff, I hereby apply for
              and consent to such diagnostic tests/services. </v-card-text
            ><v-card-text>
              I understand that every attempt will be made to maintain the
              strictest confidentiality regarding my health care, but in
              specific circumstances, it may be necessary to temporarily suspend
              that confidentiality. Limited demographic information and disease
              reporting information on those individuals receiving services will
              be provided to the Wyoming Department of Health.
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="dialog = false">
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-card-text>

    <v-divider class="mx-4 mb-4"></v-divider>

    <v-card-actions class="justify-center">
      <v-btn color="primary" class="ma-4 main-btn" @click="end"
        >I don't agree</v-btn
      >
      <v-btn color="primary" class="ma-4 main-btn" @click="start"
        >I agree</v-btn
      >
    </v-card-actions>-->
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    start() {
      this.$router.push("/pcr");
    },
    end() {
      this.$router.push("/99");
    },
  },
};
</script>

<style>
#app {
  background: #01e6ad;
}
.v-btn::before {
  background-color: #000 !important;
}
.v-card__title {
  line-height: 1.5rem;
  word-break: normal !important;
}
</style>